import { Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Link } from "react-router-dom";
import { useGetUserDataQuery, useLogoutMutation } from 'services/users';
import { useAppSelector } from 'app/hooks';
import './i18nNamespace';
import styled from 'styled-components';


const AuthContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;


export function NavBar(props: any) {
  const user = useAppSelector(state => state.users?.user);
  const realm = useAppSelector(state => state.users?.selected_realm);
  useGetUserDataQuery();
  const [logout, { isSuccess }] = useLogoutMutation();
  const navigate = useNavigate();
  

  const { t, i18n } = useTranslation();

  useEffect(() => {
    if(isSuccess) {
      navigate('/');
    }
  }, [isSuccess]);

  const submitLogout = () => {
    logout({});
  }

  return (
    <nav className="navbar navbar-expand-lg bg-light fixed-top">
      <div className="container-fluid">
        <a className="navbar-brand" href="/">
          <img src="/logo.png" alt="logo" height="40" className="d-inline-block align-text-top" />          
        </a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {t('navbar:boardingPasses')}
              </a>
              <ul className="dropdown-menu">
                <Link className="dropdown-item" to='/backoffice/boarding-passes/'>{t('navbar:list')}</Link>
                <li><hr className="dropdown-divider" /></li>
                <Link className="dropdown-item" to='/backoffice/boarding-passes/create/'>{t('navbar:create')}</Link>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {t('navbar:coupons')}
              </a>
              <ul className="dropdown-menu">
                <Link className="dropdown-item" to='/backoffice/coupons/'>{t('navbar:list')}</Link>
                <li><hr className="dropdown-divider" /></li>
                <Link className="dropdown-item" to='/backoffice/coupons/create/'>{t('navbar:create')}</Link>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {t('navbar:storeCards')}
              </a>
              <ul className="dropdown-menu">
                <Link className="dropdown-item" to='/backoffice/store-cards/'>{t('navbar:list')}</Link>
                <li><hr className="dropdown-divider" /></li>
                <Link className="dropdown-item" to='/backoffice/store-cards/create/'>{t('navbar:create')}</Link>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {t('navbar:eventTickets')}
              </a>
              <ul className="dropdown-menu">
                <Link className="dropdown-item" to='/backoffice/event-tickets/'>{t('navbar:list')}</Link>
                <li><hr className="dropdown-divider" /></li>
                <Link className="dropdown-item" to='/backoffice/event-tickets/create/'>{t('navbar:create')}</Link>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {t('navbar:genericPasses')}
              </a>
              <ul className="dropdown-menu">
                <Link className="dropdown-item" to='/backoffice/generic-passes/'>{t('navbar:list')}</Link>
                <li><hr className="dropdown-divider" /></li>
                <Link className="dropdown-item" to='/backoffice/generic-passes/create/'>{t('navbar:create')}</Link>
              </ul>
            </li>
          </ul>
          <ul className="navbar-nav mb-2 mb-lg-0">
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {i18n.language}
              </a>
              <ul className="dropdown-menu">
                <li><a className="dropdown-item" href="#" onClick={() => i18n.changeLanguage('en')}>English</a></li>
                <li><a className="dropdown-item" href="#" onClick={() => i18n.changeLanguage('ru')}>Русский</a></li>
              </ul>
            </li>
          </ul>
          <ul className="navbar-nav justify-content-end" role="search">
            { user?.id ? (
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  {user?.username} - {user?.email || realm?.name}
                </a>
                <ul className="dropdown-menu">
                  <li><a className="dropdown-item" onClick={submitLogout}>{t('navbar:logout')}</a></li>
                </ul>
              </li>
            ) : (
              <AuthContainer>
                <li className="nav-item">
                  <Link className="nav-link" to='/sign-in'>{t('navbar:signin')}</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to='/sign-up'>{t('navbar:signup')}</Link>
                </li>
              </AuthContainer>
            )}
          </ul>
        </div>
      </div>
    </nav>
  )

}

export default function App() {
  return (
    <Suspense fallback="loading">
      <NavBar />
    </Suspense>
  );
}
